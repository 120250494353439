import { useMemo } from 'react';

const useCurrencyFormatter = (value, currency, locale = 'en-US') => {
  return useMemo(() => {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
    }).format(value);
  }, [value, locale, currency]);
};

export default useCurrencyFormatter;
import i18next from 'i18next';

export const Endpoints = {
	baseUrl: 'https://api.shukdeals.com',
	frontendUrl: 'https://www.shukdeals.com',
	paymentBaseUrl: window.location.href.includes('localhost')
		? 'http://localhost:3000/'
		: 'https://www.shukdeals.com/',
	defaultCoverPic: '/media/default_images/default_cover_pic.png',
	defaultProfilePic: '/media/default_images/default_profile_pic.jpeg',
	register: 'account/register/',
	login: 'account/login/',
	logout: 'account/logout/',
	getAccessTokenFromRefreshToken: 'account/token/refresh/',
	registerSendPhoneOtp: 'account/registration-send-otp-on-phone/',
	sendPhoneOtp: 'account/send-otp-on-phone/',
	verifyOtp: 'account/otp_verify/',
	registerVerifyPhoneOtp: 'account/registration-verify-otp-phone/',
	verifyPhoneOtp: 'account/verify-otp-phone/',
	ngoList: 'account/ngos/',
	getCountries: 'account/countries/',
	getCurrency: 'account/currencies/',
	getLanguages: 'account/languages/',
	forgotPassword: 'account/forgot-password/',
	forgotVerifyCode: 'account/verify-code/',
	newPassword: 'account/new-password/',
	updatePassword: 'account/update-password/',
	getDeleteReasonList: 'account/delete-reasons/',
	DeleteSelfAccount: 'account/delete-self-account/',
	getPlans: 'account/plans/',
	activatePlan: 'account/plan/activate-new',
	updateProfile: 'account/update-profile/',
	getCategoriesList: 'account/categories/',
	getDeliveryPartners: 'account/delivery-partners',
	authenticatePassword: 'account/authenticate-password/',
	changeEmail: 'account/new-email-address',
	verifyNewEmail: 'account/change-email-address',
	addLocation: 'account/add-locations',
	getProfileDetails: 'account/profile-details',
	addorUpdateFamilyMember: 'account/add-update-family-member/',
	// Content Management
	getAboutUs: '/about-us',
	getFaqs: '/faqs',
	getTermsConditions: '/terms-and-conditions',
	getSupport: '/',
	getContactUs: 'contact-us',
	getPrivacyPolicy: '/privacy-policy',
	// flag endpoints
	flagUser: 'account/flag-user',
	flagDeal: 'deal/flag-deal',
	flagClassified: 'classified/flag-classified',
	flagUserReview: 'account/review/flag',
	flagDealReview: 'deal/review/flag',
	// classifieds
	addClassifieds: 'classified/add',
	getClassifieds: 'my-classifieds',
	getClassifiedDetails: 'classified/detail/',
	getOthersClassified: 'others-classifieds',
	getPinnedClassified: 'user-pinned-classified/',
	// Master Search
	masterSearch: 'master-search',
	masterSearchParams: 'master-search-params',
	// Job listing endpoints
	addJobListing: 'job/add',
	updateJobListing: 'job/update/',
	deleteJobListing: 'job/delete/',
	applyJobListing: 'job/apply',
	getOthersJob: 'all-jobs',
	getRecentJob: 'recent-jobs',
	getPastJob: 'past-jobs',
	getJobDetail: 'job/detail/',
	getJobStats: 'job/statistics/',
	getJobApplicants: 'job/applicants/',
	addJobWislist: 'deal/add-remove-favourite-job/',
	// user review endpoints
	addUserReview: '/account/add-review',
	getUserReviewListData: '/account/user/reviews/',
	markUserReviewUseful: '/account/review/mark-useful',
	// Deal review endpoints
	addDealReview: '/deal/add-review',
	getDealReviewListData: '/deal/reviews/',
	markDealReviewUseful: '/deal/review/mark-useful',

	// Category list endpoints
	getUserCategoryList: 'account/user-type-categories/',
	getBusinessSubCategoryList: 'account/sub-categories/',
	getClassifiedCategory: 'classified-categories',

	myWeeklyDealsList: 'deal/my-weekly-deals',
	deleteClassified: 'classified/delete/',
	updateClassified: 'classified/update/',
	addDeal: 'business-deal/add',
	getDealList: 'deals',
	deleteDeal: 'deal/delete/',
	addDealWishlist: 'deal/add-remove-favourite-deal/',
	addClassifiedWishlist: 'classified/add-remove-favourite-classified/',
	getClassifiedWishlist: 'account/all-favourite-wishlist/classified',
	getListingWishlist: 'account/all-favourite-wishlist/listing',
	getWeeklyDealWishlist: 'account/all-favourite-wishlist/weekly',
	getUsersWishlist: 'account/all-favourite-wishlist/user?user_type=member',
	getBusinessStoreWishlist:
		'account/all-favourite-wishlist/user?user_type=business',
	getNgoWishlist: 'account/all-favourite-wishlist/user?user_type=ngo',
	getNgoVideoWishlist: 'account/all-favourite-wishlist/video',
	getJobWishlist: 'account/all-favourite-wishlist/job',
	getRelations: 'account/relations/',
	removeFamilyMember: 'account/delete-family-member/',
	pinClassified: 'classified/pin-classified/',
	getBusinessCategoryDeals: 'deal/business-category-deals/',
	updateDeal: 'deal/update/',
	othersWeeklyDeals: 'deal/others-weekly-deals',
	getNgoPartnersList: 'account/ngo-partners/',
	getRecommendedDeals: 'deal/recommended-deals',
	getUsersRecommendedDeals: 'deal/user-recommended-deals/',
	topStoreBusinessProfile: 'account/business-profiles/',
	getRealEstateDeals: 'deal/real-estate-deals/',
	getDealDetails: 'deal/detail/',
	addPropertyDeal: 'property-deal/add',
	updatePropertyDeal: 'property-deal/update/',
	addUserWishlist: 'account/add-remove-favourite-user/',
	searchBusinessDeals: 'deal/all-business-deals',
	getOtherUserProfile: 'account/other-profile-details/',
	// videos
	addNgoVideo: 'account/add-ngo-video',
	updateNgoVideo: 'account/update-ngo-video/',
	getNgoVideos: 'account/ngo-videos',
	getNgoVideoDetails: 'account/ngo-video-detail/',
	likeNgoVideos: 'account/like-unlike-ngo-video/',
	addRemoveFavouriteNgoVideos: 'account/add-remove-favourite-video/',
	deleteNgoVideos: 'account/delete-ngo-video/',

	getMemberList: 'account/member-users/',
	getUserClassified: 'user-classifieds/',
	getUserDeal: 'user-deals/',
	planActivateFinalize: 'account/plan/finalize',
	getUnitCost: 'account/unit-product-cost',
	// payment endpoints
	addLocationPayment: 'account/add-locations-payment',
	checkLocationsPayment: 'account/check-locations',
	tranzillaPaymentProcess: 'process-tranzila-payment',
	generateStripeIntent: '/generate-stripe-intent',

	getTranzilaToken: '/get-tranzila-token',
	getNgoPaymentHistory: 'account/ngo-payment-history',
	getNgoPaymentReport: 'account/ngo-payment-report',
	getPaymentHistory: 'account/my-payment-history',
	getPaymentReport: 'account/my-payment-report',
	getPaymentInvoice: 'account/download-payment-invoice/',
	getBasicInfofromTempTOken: 'account/get-basicinfo-from-temptoken/',
	redeemDeal: '/deal/redeem/',
	myRedeemedDeals: 'deal/my-redeemed-deals',
	myRedeemedDealsUsers: 'deal/redeemers/',
	getPropertyDeal: 'deal/all-real-estate-deals',
	getPropertyTypes: 'property-types',
	getLocationList: 'account/locations',
	deleteLocation: 'account/delete-location/',
	updateLocation: 'account/update-location/',
	getStripeExistingCards: 'get-stripe-payment-methods',
	savedStripeProcess: 'process-stripe-saved',
	getAllNgoDeals: 'deal/ngo-deals',
	updateNgo: 'account/update-ngo',
	resendOtp: 'account/resend-otp/',
	resendOtpChangeEmail: 'account/resend-otp-email-update/',
	getStripePaymentMethodList: 'list-stripe-payment-methods',
	deleteStripePaymentMethod: 'delete-stripe-payment-method',
	addTranzillaPaymentMethod: 'add-new-tranzila-payment-method',
	deleteTranzillaPaymentMethod: 'delete-tranzila-payment-method/',
	createCheckOutSession: 'create-stripe-checkout-session',
	addStripePaymentMethod: 'add-stripe-payment-method',
	makeStripeDefault: 'markdefault-stripe-payment-method/',
	makeTranzillaDefault: 'markdefault-tranzila-payment-method/',
	getStripePaymentStatus: 'get-stripe-payment-status/'
};
